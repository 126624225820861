import { tracksConstants } from '../constants/tracks';
import { tracksService } from '../services/tracks';
import { imprintsService } from '../services/imprints';
import { playlistsService } from '../services/playlists';
import { tracksFormatter } from '../formatters/tracks';
import { find, map } from 'lodash';

export const tracksActions = {
    getTracks,
    getTopTracks,
    getTopTracksInPlaylist,
    getTracksAutocomplete,
    getTrackDetails,
    getCard,
    getStreamsStats,
    getMetadata,
    getTimeseriesTracks,
    getTimeseriesTracksInPlaylist,
    compareTracks,
    compareTrackDemographics,
    compareTrackTerritories,
    compareTrackVendors,
    compareProductTracks,
    getTracksReleaseDates,
    getTiktokTimeseries,
    getTiktokTerritories,
    getTiktokTerritoriesTimeseries,
    getTiktokMetrics,
    compareTrackArtist
};

function getTracks(params, basic) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        tracksService.getTracks(params, globalFilter, basic)
            .then(
                tracks => {
                    const data = tracksFormatter.formatForTable(tracks);
                    //dispatch(success(data, tracks.pages));
                    return dispatch(getMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_REQUEST } }
    function success(tracks, pages) { return { type: tracksConstants.GET_TRACKS_SUCCESS, tracks, pages } }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_FAILURE, error } }
}


function getTracksAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search)
            return dispatch(success([]));
        tracksService.getTracksAutocomplete(search)
            .then(
                tracks => {
                    const data = tracksFormatter.formatAutocomplete(tracks.results, ['tracks']);
                    dispatch(success(data.slice(0, limit)));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_AUTOCOMPLETE_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TRACKS_AUTOCOMPLETE_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopTracks(params, useCache, filter, advanced = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;

        const fields =  advanced ? ['*'] : ['track_id', 'isrc', 'curr_units', 'prev_units', 'units_diff', 'passive', 'active', 'skipped_audio_ratio', 'skipped_video_ratio', 'completed_audio_ratio', 'completed_video_ratio', 'curr_playlists', 'prev_playlists', 'playlists_diff', 'vendors', 'audio_compressed', 'audio_lossless', 'audio_immersive', 'free', 'discounted', 'premium', 'lyrics_viewed', 'canvas_viewed'  ];
        if(filter) {
            let filtered = [];
//            if(filter.tracks){
//                filtered.push({
//                    id: 'tracks',
//                    value: filter.tracks                    
//                })
//            }
//            else {
                for(let entity of Object.keys(filter)) {
                    filtered.push({
                        id: entity,
                        value: filter[entity]
                    })
                };
//            }

            params.filtered = filtered;
        }
        
        return tracksService.getTopTracks(params, globalFilter, fields)
            .then(
                tracks => {
                    const data = tracksFormatter.formatTop(tracks);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TOP_TRACKS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TOP_TRACKS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TOP_TRACKS_FAILURE, error } }
}

function getTopTracksInPlaylist(playlistID, parentEntity, parentEntityID, useCache, filter) {
    const fields = ['track_id', 'isrc', 'curr_units', 'prev_units', 'passive', 'active', 'curr_skipped', 'curr_completed', 'skipped_ratio', 'completed_ratio', 'added_at', 'removed_at', 'position', 'curr_male', 'curr_female', 'curr_unknown'];
    let params = {
        sorted: {field: 'curr_units', dir: 'DESC'}, 
        filtered: [{id: 'playlists', value: playlistID}]
    };
    
    if(parentEntity && parentEntityID) {
        params.filtered.push({id: parentEntity, value: parentEntityID});
    }
    
    if(filter) {
        let filtered = [];
        for(let entity of Object.keys(filter)) {
            params.filtered.push({
                id: entity,
                value: filter[entity]
            })
        };
        // params.filtered = filtered;
    }

    return ( dispatch, getState ) => {
        dispatch(request(playlistID));
        const globalFilter = getState().filter.global;
        return tracksService.getTopTracksInPlaylist(params, globalFilter, fields)
            .then(
                tracks => {
                    const data = tracksFormatter.formatTop(tracks);
                    //dispatch(success(data));
                    return dispatch(getTopTracksInPlaylistMetadata(data, playlistID));
                },
                error => {
                    dispatch(failure(playlistID, 'error'))
                }
            );
    };

    function request(playlistID) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_REQUEST, playlistID } }
    function success(tracks) { return { type: tracksConstants.GET_TOP_TRACKS_SUCCESS, tracks} }
    function failure(playlistID, error) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_FAILURE, playlistID, error } }
}


function getTrackDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const tracks = getState().tracks.top,
            track = find(tracks, {track_id: id});
        return Promise.resolve(track)
            .then(
                artist => {
                    const data = tracksFormatter.formatDetails(track.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACK_DETAILS_REQUEST } }
    function success(id, track) { return { type: tracksConstants.GET_TRACK_DETAILS_SUCCESS, id, track } }
    function failure(error) { return { type: tracksConstants.GET_TRACK_DETAILS_FAILURE, error } }
}

function getCard(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return tracksService.getCard(id)
            .then(
                card => {
                    card= tracksFormatter.formatCard(card);
                    dispatch(success(id, card));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_CARD_REQUEST } }
    function success(id, card) { return { type: tracksConstants.GET_TRACKS_CARD_SUCCESS, id, card } }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_CARD_FAILURE, error } }
}

function getStreamsStats(ids, filtered = []) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        tracksService.getStreamsStats(ids, filtered, globalFilter)
            .then(
                tracks => {
                    const metadata = getState().tracks.metadata;
                    tracks = tracksFormatter.formatStreamsStats(tracks, metadata);
                    dispatch(success(tracks));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_STREAMS_STATS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_STREAMS_STATS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        return tracksService.getMetadata(ids)
            .then(
                metadata => {
                    entities = tracksFormatter.formatMetadata(entities, metadata.data);
                    dispatch(success(entities, metadata.data));
                    return entities;

                },
                error => {
                    console.log('tracks'+error);
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TOP_TRACKS_REQUEST } }
    function success(tracks, metadata) { return { type: tracksConstants.GET_TOP_TRACKS_SUCCESS, tracks, metadata } }
    function failure(error) { return { type: tracksConstants.GET_TOP_TRACKS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        const imprintIds = entities.data.map(entity=>entity.imprint_id);
        const imprints = (imprintIds.length && imprintIds[0]) ? imprintsService.getMetadata(imprintIds) : Promise.resolve({data:[]});
        Promise.all([tracksService.getMetadata(ids), imprints]) 
            .then(
                ([metadata, imprintMetadata]) => {
                    const data = tracksFormatter.formatMetadata(entities.data, metadata.data, imprintMetadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    console.log(error);
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TRACKS_REQUEST } }
    function success(tracks, total, metadata) { return { type: tracksConstants.GET_TRACKS_SUCCESS, tracks, total, metadata } }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_FAILURE, error } }

}

function getTopTracksInPlaylistMetadata(entities, playlistID) {
    return ( dispatch, getState ) => {
        dispatch(request(playlistID));
        const ids = entities.map(entity=>entity.id);
        return tracksService.getMetadata(ids)
            .then(
                metadata => {
                    const data = tracksFormatter.formatMetadata(entities, metadata.data);
                    dispatch(success(data, metadata.data, playlistID));
                    return data;

                },
                error => {
                    dispatch(playlistID, failure('error'))
                }
            );
    };

    function request(playlistID) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_REQUEST, playlistID } }
    function success(tracks, metadata, playlistID) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_SUCCESS, tracks, metadata, playlistID } }
    function failure(playlistID, error) { return { type: tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_FAILURE, playlistID, error } }

}


function getTimeseriesTracks(params, useCache, filter) {
    return ( dispatch, getState ) => {
        if(getState().tracks.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }
        
        return tracksService.getTimeseriesTracks(params, globalFilter)
            .then(
                tracks => {
                    const metadata = getState().tracks.metadata;
                    const data = tracksFormatter.formatStreamsStats(tracks, metadata);
                    dispatch(success(data));
                    return tracks;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIMESERIES_TRACKS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_FAILURE, error } }
}

function getTimeseriesTracksInPlaylist(params, useCache, filter) {
    return ( dispatch, getState ) => {
        const timeseries = getState().tracks;
        if(timeseries && timeseries.topPlaylistTimeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        const ids = params.filtered[1].value;
        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }

        return Promise.all([tracksService.getTimeseriesTracksInPlaylist(params, globalFilter), 
            tracksService.getMetadata(ids)])
            .then(
                results => {
                    const tracks = results[0];
                    const metadata = results[1].data;
                    const data = tracksFormatter.formatStreamsStats(tracks, metadata);
                    dispatch(success(data));
                    return tracks;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_REQUEST} }
    function success(tracks) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_FAILURE, error } }
}

function compareTracks(ids, weeks, releaseDates, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTracks(ids, weeks, releaseDates, territories, vendors, currentUser)
            .then(
                tracks => {
                    const data = tracksFormatter.formatCompareTracks(ids, tracks);
                    return dispatch(success(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACKS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.COMPARE_TRACKS_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACKS_FAILURE, error } }
}

function compareTrackDemographics(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackDemographics(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                track => {
                    const data = tracksFormatter.formatCompareTrackDemographics(track);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_FAILURE, error } }
}

function compareTrackTerritories(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackTerritories(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                track => {
                    const data = tracksFormatter.formatCompareTrackTerritories(track);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACK_TERRITORIES_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_TERRITORIES_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_TERRITORIES_FAILURE, error } }
}

function compareTrackVendors(id, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackVendors(id, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                track => {
                    const data = tracksFormatter.formatCompareTrackVendors(track);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_TRACK_VENDORS_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_VENDORS_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_VENDORS_FAILURE, error } }
}

function getTracksReleaseDates(ids) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return tracksService.getTracksReleaseDates(ids)
            .then(
                tracks => {
                    const trackIDs = map(tracks.data, 'track_id');
                    return Promise.all(map(trackIDs, (id)=>tracksService.getCard(id)))
                    .then(cards => {
                        const data = tracksFormatter.formatTracksReleaseDates(tracks, cards, ids);
                        dispatch(success(data));
                        return data;
                    })
                },
                error => {
                    dispatch(failure('error'))
                }
            )
    };

    function request() { return { type: tracksConstants.GET_TRACKS_RELEASE_DATES_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TRACKS_RELEASE_DATES_SUCCESS, tracks} }
    function failure(error) { return { type: tracksConstants.GET_TRACKS_RELEASE_DATES_FAILURE, error } }
}

function getTiktokTimeseries(filtered, dateGroup) {
    return ( dispatch, getState ) => {
        if(getState().tracks.tiktokTimeseriesLoading)
            return;
        
        dispatch(request());
        let filterObject = {};
        if(Array.isArray(filtered.tracks)) {
            filterObject.tracks = filtered.tracks;
        }
        else {
            for(let entity of Object.keys(filtered))
                filterObject[entity] = filtered[entity]
        }
        filterObject.content_type = [7, 8];
        if(dateGroup)
            filterObject['date_grouping'] = dateGroup;
        
        const globalFilter = getState().filter.global;
        const params = {filtered: Object.keys(filterObject).map(key=>({id: key, value: filterObject[key]}))};
        
        return tracksService.getTiktokTimeseries(params, globalFilter)
            .then(
                (tracks) => {
                    const ids = map(tracks, track=>track.track_id);
                    tracksService.getMetadata(ids)
                    .then(metadata => {
                        const data = tracksFormatter.formatTiktokTimeseries(tracks, metadata.data);
                        dispatch(success(data));
                        return tracks;
                    })
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIKTOK_TIMESERIES_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TIKTOK_TIMESERIES_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TIKTOK_TIMESERIES_FAILURE, error } }
}

function getTiktokTerritories(filtered, dateGroup) {
    return ( dispatch, getState ) => {
        if(getState().tracks.tiktokTerritoriesLoading)
            return;
        
        dispatch(request());
        let filterObject = {};
        for(let entity of Object.keys(filtered))
            filterObject[entity] = filtered[entity]

        filterObject.content_type = [7, 8];
        if(dateGroup)
            filterObject['date_grouping'] = dateGroup;
        
        const globalFilter = getState().filter.global;
        const params = {filtered: Object.keys(filterObject).map(key=>({id: key, value: filterObject[key]}))};
        
        return tracksService.getTiktokTerritories(params, globalFilter)
            .then(
                (territories) => {
                    const data = tracksFormatter.formatTiktokTerritories(territories);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIKTOK_TERRITORIES_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TIKTOK_TERRITORIES_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TIKTOK_TERRITORIES_FAILURE, error } }
}

function getTiktokTerritoriesTimeseries(filtered, dateGroup) {
    return ( dispatch, getState ) => {
        if(getState().tracks.tiktokTerritoriesTimeseriesLoading)
            return;
        
        dispatch(request());
        let filterObject = {};
        for(let entity of Object.keys(filtered))
            filterObject[entity] = filtered[entity]

        filterObject.content_type = [7, 8];
        if(dateGroup)
            filterObject['date_grouping'] = dateGroup;
        
        const globalFilter = getState().filter.global;
        const params = {filtered: Object.keys(filterObject).map(key=>({id: key, value: filterObject[key]}))};
        
        return tracksService.getTiktokTerritoriesTimeseries(params, globalFilter)
            .then(
                (territories) => {
                    const data = tracksFormatter.formatTiktokTerritoriesTimeseries(territories);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIKTOK_TERRITORIES_TIMESERIES_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TIKTOK_TERRITORIES_TIMESERIES_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TIKTOK_TERRITORIES_TIMESERIES_FAILURE, error } }
}


function getTiktokMetrics(filtered) {
    return ( dispatch, getState ) => {
        dispatch(request());
        let filterObject = {};
        if(Array.isArray(filtered.tracks)) {
            filterObject.tracks = filtered.tracks;
        }
        else {
            for(let entity of Object.keys(filtered))
                filterObject[entity] = filtered[entity]
        }
        filterObject.content_type = [7, 8];
        
        const globalFilter = getState().filter.global;
        const params = {filtered: Object.keys(filterObject).map(key=>({id: key, value: filterObject[key]}))};
        
        return tracksService.getTiktokMetrics(params, globalFilter)
            .then(
                (tracks) => {
                    const ids = map(tracks, track=>track.track_id);
                    tracksService.getMetadata(ids)
                    .then(metadata => {
                        const data = tracksFormatter.formatTiktokMetrics(tracks, metadata.data);
                        dispatch(success(data));
                        return tracks;
                    })
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.GET_TIKTOK_METRICS_REQUEST } }
    function success(tracks) { return { type: tracksConstants.GET_TIKTOK_METRICS_SUCCESS, tracks } }
    function failure(error) { return { type: tracksConstants.GET_TIKTOK_METRICS_FAILURE, error } }
}

function compareTrackArtist(id, artistID, weeks, releaseDate, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        tracksService.compareTrackArtist(id, artistID, weeks, releaseDate, territories, vendors, currentUser)
            .then(
                streams => {
                    const data = tracksFormatter.formatCompareTrackArtist(streams);
                    return dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };
    function request() { return { type: tracksConstants.COMPARE_TRACK_ARTIST_REQUEST } }
    function success(id, track) { return { type: tracksConstants.COMPARE_TRACK_ARTIST_SUCCESS, id, track} }
    function failure(error) { return { type: tracksConstants.COMPARE_TRACK_ARTIST_FAILURE, error } }
}

function compareProductTracks(productId, tracks, weeks, releaseDates, territories, vendors) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const currentUser = getState().user.user;
        const ids = map(tracks, 'isrc');
        tracksService.compareProductTracks(ids, weeks, releaseDates, territories, vendors, currentUser)
            .then(
                data => {
                    const datasets = tracksFormatter.formatCompareProductTracks(data, tracks);
                    return dispatch(success(productId, datasets));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: tracksConstants.COMPARE_PRODUCT_TRACKS_REQUEST } }
    function success(productId, tracks) { return { type: tracksConstants.COMPARE_PRODUCT_TRACKS_SUCCESS, productId, tracks} }
    function failure(error) { return { type: tracksConstants.COMPARE_PRODUCT_TRACKS_FAILURE, error } }
}
